.type1 {
    font-size: 16px;
    text-align: center;
    color: #1d55d7;
    min-width: 200px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #4f7eee;
    position: relative;
    white-space: nowrap;
    >div {
        padding: 0 26px;
    }
}

[data-hint] {
    position: relative;
}

[data-hint]::after {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    position: absolute;
    white-space: pre;
    bottom: -40px;
    left: 85%;
    opacity: 0;
    color: #FFFFFF;
    background-color: #49505C;
    border-radius: 4px;
    width: max-content;
    min-width: 252px;
    max-width: 400px;
    padding: 6px 10px 7px;
    content: attr(data-hint);
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    pointer-events: none;
    transition: opacity 0.2s ease-out;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
}

[data-hint]:hover::after {
    opacity: 1;
}

.button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 236px;
    padding: 0 30px;
    margin: 0;
    height: 46px;
    border-radius: 4px;
    color: #FFFFFF;
    background: rgba(79, 126, 238, 1);
    box-shadow: 0 5px 4px rgba(14, 13, 9, 0.06), 0 2px 3px rgba(14, 13, 9, 0.08), 0 1px 2px rgba(14, 13, 9, 0.15);
    transition: all .2s ease-out;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    &:hover {
        background-color: #1D55D7;
        &:disabled {
            background: rgba(79, 126, 238, 0.5);
            box-shadow: none;
        }
    }

    &.disablePointer {
        &:hover {
            &:disabled {
                cursor: pointer;
            }
        }
    }

    &:disabled {
        background: rgba(79, 126, 238, 0.5);
        box-shadow: none;
    }


    &.red {
        background-color: #F2F1ED;
        border: 1px rgba(207, 38, 58, 0.5) solid;
        color: rgba(207, 38, 58, 1);
        box-shadow: none;

        &:hover {
            background-color: #FFF7F7;

            &:disabled {
                color: #696870;
                border-color: #CCD1D9;
                background: #F2F1ED;
            }
        }

        &:disabled {
            color: #696870;
            border-color: #CCD1D9;
        }
    }

    &.transparent {
        background-color: transparent;
        color: rgba(29, 85, 215, 1);
        border: 1px solid #4F7EEE;
        box-shadow: none;

        &:hover {
            background-color: #ECF5FF;
            &:disabled {
                color: #696870;
                border-color: #CCD1D9;
                background: #F2F1ED;
            }
        }

        &:disabled {
            color: #696870;
            border-color: #CCD1D9;
        }
    }

    &.transparentBlueHover {
        background-color: transparent;
        color: rgba(29, 85, 215, 1);
        border: 1px solid #4F7EEE;
        box-shadow: none;

        &:hover {
            background-color: rgba(230, 237, 252, 1);
            &:disabled {
                color: #696870;
                border-color: #CCD1D9;
                background: #F2F1ED;
            }
        }

        &:disabled {
            color: #696870;
            border-color: #CCD1D9;
        }
    }

    &.transparentGreyHover {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.65);
        border: 1px solid rgba(0, 0, 0, 0.25);
        box-shadow: none;
        &:hover {
            background-color: #F7F7F7;
            color: rgba(0, 0, 0, 0.8);
            &:disabled {
                color: rgba(0, 0, 0, 0.28);
                border-color: rgba(0, 0, 0, 0.1);
                background: transparent;
            }
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.28);
            border-color: rgba(0, 0, 0, 0.1);
        }
    }

    &.green {
        background: #18AD74;
        color: #FFFFFF;
    }

    &.marginRight15 {
        margin-right: 15px;
    }

    &.withoutBorderRadius {
        border-radius: 0;
    }

    &.minHeight46 {
        min-height: 46px;
    }

    &.width195 {
        min-width: 195px;
        width: 195px;
    }

    &.width259 {
        min-width: 259px;
        width: 259px;
    }

    &.width123 {
        min-width: 123px;
        width: 123px;
        padding: 0 0;
    }

    &.button30 {
        border-color: transparent;
        min-width: 30px;
        width: 30px;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 30px;
        height: 30px;
        flex-flow: row wrap;
        padding: 0;
        margin: 0;
        &:hover {
            background-color: transparent;
        }
    }

    &.withoutBorder {
        border-color: transparent;
    }

    &.flexStart {
        justify-content: flex-start;
        padding: 0 8px;
    }

    &.hoverUnderline {
        &:hover {
            text-decoration: underline;
            background-color: transparent;
            transition: all .2s ease-out;
        }
    }

    &.marginBottom16 {
        margin-bottom: 16px;
    }
    &.margin0padding0 {
        margin: 0;
        padding: 0;
    }

    &.margin0_0_32_24 {
        padding: 0;
        margin: 0 0 32px 24px;
    }

    &.relative {
        position: relative;
    }
}

.basket {
    display: flex;
    background: url('./assets/deleteImage.svg') transparent center center no-repeat;
    min-width: 28px;
    box-sizing: border-box;
    min-height: 28px;
    flex-flow: row wrap;
    padding: 0;
    margin: 0;
    transition: all ease-out .2s;
    &:hover {
        background-image: url('./assets/red_trash.svg');
    }
}
.crossBtn {
    display: flex;
    background: url('./assets/cross_grey.svg') transparent center center no-repeat;
    min-width: 28px;
    box-sizing: border-box;
    min-height: 28px;
    flex-flow: row wrap;
    padding: 0;
    margin: 0;
    transition: all ease-out .2s;
    &:hover {
        background-image: url('./assets/cross_red.svg');
    }
}

